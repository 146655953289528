/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://7sp7qt7qubb3fmuq6ug5wn2rou.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-goniio7z4zftxacdoullxksqze",
    "aws_cloud_logic_custom": [
        {
            "name": "gmailAuthorizeAPI",
            "endpoint": "https://garhgn1150.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "gmailEndpointsAPI",
            "endpoint": "https://p2jsoriekd.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "googleAnalyticsAPI",
            "endpoint": "https://ri6yyw21s8.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "googleCalendarAuthorizeAPI",
            "endpoint": "https://sjzukrwpji.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "googleCalendarEndpointsAPI",
            "endpoint": "https://8argch6bj1.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpAuthorizeAPI",
            "endpoint": "https://l1cih597ij.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpEndpointsAPI",
            "endpoint": "https://0xkofm85zc.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpSyncAPI",
            "endpoint": "https://g0ugq5gqv0.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        },
        {
            "name": "mailchimpWebhookAPI",
            "endpoint": "https://w2gdha4084.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:472e9169-ff61-4c4a-b725-137a0cfb2685",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_PS43b1nSS",
    "aws_user_pools_web_client_id": "5faagemccd91qrce13rtd3bfv9",
    "oauth": {
        "domain": "panafoldcmsff7d7f48-ff7d7f48-staging.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3030/",
        "redirectSignOut": "http://localhost:3030/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "panafold-connect-storagestaging-staging",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
